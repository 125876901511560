import {COCOLIS_TRANSPORTER} from "assets/js/constants/constant";

export const getLocalisedTransporters = (transporters, transporterHandToHand, countryFrom, $auth, config) => {
  const countryTo = $auth.loggedIn && $auth.user.addresses.deliveryAddress
    ? $auth.user.addresses.deliveryAddress.country.isoCode
    : config.app.location.country_iso;

  const filteredTransporters = transporters.filter(transporter => {

    if (transporter.id === COCOLIS_TRANSPORTER && transporter.priceTTC?.length && transporter.priceTTC[0]?.isImpossible) {
      return false;
    }

    const matchingTransporters = [];

    transporter.priceTTC.forEach(price => {
      if (price.from === countryFrom && price.to === countryTo) {
        matchingTransporters.push(transporter);
      }
    })

    return matchingTransporters.length > 0;
  }).sort((a, b) => {

    const aPrice = getTransporterPrice(a, countryFrom, $auth, config)?.price

    if (aPrice == 0) {
      return 1
    }

    const bPrice = getTransporterPrice(b, countryFrom, $auth, config)?.price

    if (bPrice == 0) {
      return -1
    }

    return aPrice > bPrice ? 1 : -1
  });

  return filteredTransporters.length > 0 ? filteredTransporters : [transporterHandToHand]
}

export const getTransporterPrice = (transporter, countryFrom, $auth, config) => {
  const countryTo = $auth.loggedIn && $auth.user.addresses.deliveryAddress
    ? $auth.user.addresses.deliveryAddress.country.isoCode
    : config.app.location.country_iso;

  const priceTTC = transporter.priceTTC.find(price => price.from === countryFrom && price.to === countryTo)

  if (!priceTTC) {
    return {
      price: 0,
      price_with_insurance: 0,
      price_insurance: 0,
      isImpossible: true
    }
  }
  return {
    price: parseFloat(priceTTC.price),
    price_with_insurance: parseFloat(priceTTC.price_with_insurance),
    price_insurance: parseFloat(priceTTC.price_insurance),
    isImpossible: priceTTC.isImpossible,
    label: priceTTC.label,
    description: priceTTC.description
  }
}

import {BADGE_ICONS, HAND_TO_HAND_TRANSPORTER, STYLE_TYPES} from "assets/js/constants/constant";

export const slugToID = (slug) => {

  let list;
  let id = null;

  if (slug) {
    if (slug.indexOf(',') === -1) {
      list = slug.split('-');
      id = slug ? list[0] : null;
    } else {
      list = slug.split(',');
      id = slug ? list[list.length - 1] : null;
    }
  }


  return id !== null && typeof id === 'string' && id.length ? id : null;
}

export const getProductServicesForTransporters = (product, transporters) => {
  if (transporters.length === 1 && transporters[0].id === HAND_TO_HAND_TRANSPORTER) {
    return []
  }

  const productServices = product.services2 ? product.services2 : product.services
  return productServices?.filter(service => {
    return transporters.filter(transporter => !service.transporters_ids?.length || service.transporters_ids?.includes(transporter.id))?.length > 0
  })
}

export const getProductStatus = (genre, productState, $i18n) => {
  switch(genre) {
    case 2:
      return {
        label: $i18n.t('label.athlete'),
        type: STYLE_TYPES.primary,
        individual: false
      }
    case 4:
      return {
        label: $i18n.t('label.pro'),
        type: STYLE_TYPES.primary,
        individual: false
      }
    case 5:
      return {
        label: $i18n.t('label.refurbished'),
        type: STYLE_TYPES.skyblue,
        individual: false
      }
    default:
      switch (productState) {
        case 1:
        case 2:
          return {
            label: $i18n.t('product.new'),
            type: STYLE_TYPES.white,
            icon: 'chip',
            iconColor: 'text-primary',
            individual: true
          }
      }
  }
  return null
}

export const toLightProduct = (product) => {
  return {
    sizeId: product.sizeId,
    catalogId: product.catalogId,
    visible: product.visible,
    stateId: product.stateId,
    name: product.name,
    images: product.images,
    images_rotations: product.images_rotations,
    slug2: product.slug2,
    brand: {
      name: product.brand?.name,
    },
    price: product.price,
    drop_price: product?.drop_price,
    available_at: product?.available_at,
    percent_discount: product?.percent_discount,
    purchase_price: product.purchase_price,
    badge: product?.badge,
    user: {
      image: product?.user?.image,
      pseudo: product?.user?.pseudo,
      score: product?.user?.score,
      genre: product?.user?.genre,
      profilType: product?.user?.profilType
    },
  }
}

import {BADGE_ICONS, STORAGE_KEYS, STYLE_TYPES} from "assets/js/constants/constant";
import {Storage} from "@capacitor/storage";

export const getUserStatus = (genre, profilType, $i18n) => {
  switch(genre) {
    case 2:
      return {
        label: $i18n.t('label.athlete'),
        type: STYLE_TYPES.primary,
        individual: false
      }
    case 4:
      return {
        label: $i18n.t('label.pro'),
        type: STYLE_TYPES.primary,
        individual: false
      }
    case 5:
      return {
        label: $i18n.t('label.brand'),
        type: STYLE_TYPES.primary,
        individual: false
      }
  }
  switch(profilType) {
    case 1:
      return {
        label: $i18n.t('label.superseller'),
        icon: BADGE_ICONS.medal,
        type: STYLE_TYPES.primary,
        individual: true
      }
    case 2:
      return {
        label: $i18n.t('label.powerseller'),
        icon: BADGE_ICONS.thunder,
        type: STYLE_TYPES.warning,
        individual: true
      }
    default:
      return {
        label: $i18n.t('label.verified'),
        icon: BADGE_ICONS.checkedBadge,
        type: STYLE_TYPES.accent,
        individual: true
      }
  }
}

export const updateRead = (app, store, $api) => {
  if(app && app.$auth && app.$auth.$state.loggedIn) {
    $api.$get(process.env.apiUrl + '/api/v2/conversations/unread-count').then(response => {
      Storage.get({key: STORAGE_KEYS.hasReadEverideMessage}).then(hasReadEverideMessage => {
        Storage.get({key: STORAGE_KEYS.hasRemovedEverideMessage}).then(hasRemovedEverideMessage => {
          const messageCount = response.messages + ((hasRemovedEverideMessage?.value || hasReadEverideMessage?.value) ? 0 : 1)

          store.commit('setMessageToRead', messageCount);
          store.commit('setNotificationToRead', response.notifications);
        })
      })
    });
  }
}

export const MAX_PSEUDO = 45;
export const MAX_DESC = 800;
export const MAX_PRODUCT_NAME = 40;
export const MAX_IBAN = 20;

export const PICKUP_TRANSPORTERS = [1,2];
export const INSURANCE_TRANSPORTERS = [7];
export const PHONE_TRANSPORTERS = [7,6,5,4,3,2,1];

export const HAND_TO_HAND_TRANSPORTER = 4
export const COCOLIS_TRANSPORTER = 7

export const PHONE_PREFIX = {
  other: 'OTHER'
}
export const LOGIN_STEPS = {
  welcome: 'Welcome',
  checkEmail: 'CheckEmail',
  login: 'Login',
  forgotPassword: 'ForgotPassword',
  resetPassword: 'ResetPassword',
  register: 'Register',
  completeProfile: 'CompleteProfile'
}

export const POPIN_COMPONENTS = {
  addressForm: 'AddressPopin',
  confirmDelivery: 'chat/ConfirmDeliveryPopin',
  paymentMethod: 'PaymentMethodPopin',
  remove: 'product/RemovePopin',
  status: 'StatusPopin',
  pickedUp: 'chat/PickedUpPopin',
  picturePopin: 'PicturePopin',
  pickupSelector: 'PickupPopin',
  signal: 'SignalPopin',
  payin: 'PayinPopin',
  share: 'product/SharePopin',
  contact: 'ContactPopin',
  deleteAccount: 'DeleteAccountPopin',
  offer: 'cookie/OfferPopin',
  printing: 'chat/PrintingPopin',
  incident: 'chat/IncidentPopin',
  cancelOrder: 'chat/CancelOrderPopin',
  states: 'StatesPopin',
  dynamic: 'DynamicPopin',
  review: 'chat/ReviewPopin',
  signalUser: 'chat/SignalUserPopin',
  signalProduct: 'product/SignalProductPopin',
  evercover: 'product/EvercoverPopin',
  productInformation: 'product/ProductInformationPopin',
  productReturn: 'product/ProductReturnPopin',
  productTransporters: 'product/ProductTransportersPopin',
  productPayment: 'product/ProductPaymentPopin',
  productCarbon: 'product/ProductCarbonPopin',
  pictureGallery: 'PictureGalleryPopin',
  productState: 'product/ProductStatePopin',
  success: 'SuccessPopin',
  paymentAdd: 'PaymentAddPopin',
  loading: 'LoadingPopin',
  confirmTransfer: 'wallet/ConfirmTransferPopin',
  login: 'LoginPopin',
  notifications: 'NotificationPopin',
  productPrice: 'product/Form/PricePopin',
  productDelivery: 'product/Form/DeliveryPopin',
  formAddress: 'product/Form/AddressPopin',
  action: 'ActionPopin',
  helpPhoto: 'product/HelpPhotoPopin',
  flags: 'FlagsPopin',
  productServices: 'product/ProductServicesPopin',
  saveSearch: 'search/SaveSearchPopin',
  filterSearch: 'search/FilterSearchPopin',
  html: 'HtmlPopin'
}

export const BRAND_TYPES = {
  air: 'air',
  water: 'water',
  bike: 'bike',
  default: 'default',
}
export const STYLE_TYPES = {
  default: 'default',
  light: 'light',
  lighter: 'lighter',
  info: 'info',
  error: 'error',
  warning: 'warning',
  primary: 'primary',
  darker: 'darker',
  accent: 'accent',
  white: 'white',
  skyblue: 'sky-blue',
  inversedPrimary: 'inversed-primary',
  inversedDarker: 'inversed-darker'
}

export const FAVORITE_TYPES = {
  product: 1,
  user: 2,
}

export const CONTACT_TYPES = {
  contact: 'contact',
  pro: 'pro',
  order: 'order',
  kyc: 'kyc'
}
export const BADGE_ICONS = {
  medal: 'medal',
  checkedBadge: 'checked-badge',
  thunder: 'thunder',
  warning: 'warning'
}

export const KYC_TYPE = {
  id: 'id',
  license: 'license',
  passport: 'passport',
  other: 'other'
}

export const STORAGE_KEYS = {
  firstLaunch: 'FIRST_LAUNCH',
  howToSellSeenDate: 'HOW_TO_SELL_SEEN_DATE',
  hasSeenIntroductionSteps: 'HAS_SEEN_INTRODUCTION_STEPS',
  notificationToken: 'NOTIFICATION_TOKEN',
  enabledNotifications: 'ENABLED_NOTIFICATIONS',
  conversationsTranslated: 'CONVERSATIONS_TRANSLATED',
  hasReadEverideMessage: 'HAS_READ_EVERIDE_MSG',
  hasRemovedEverideMessage: 'HAS_REMOVED_EVERIDE_MSG'
}

export const NOTIFICATIONS_STATUS = {
  later: 'later',
  true: 'true',
  false: 'false'
}

export const BREAKPOINTS = {
  'xs' : '0px',
  //'sm': '640px',
  'md': '768px',
  'lg': '1024px',
  'xl': '1280px',
  //'2xl': '1536px'
}

export const STATUS = {
  'success': 'success',
  'error': 'error'
}

export const PAYMENT_BANK = {
  'default': 'DEFAULT',
  'bcmc': 'BCMC'
}

export const PAYMENT_TYPE = {
  'card' : 'card',
  'bcmc': 'bancontact',
  'paypal': 'paypal',
  'payconiq': 'payconiq',
  'wallet': 'wallet',
  'bankwire': 'bankwire',
  'applepay': 'applepay',
  'googlepay': 'googlepay'
}

export const PRICE_LIMIT_FOR_CB = 4000

export const PROVIDER = {
  's2s' : 'chronopostShop2Shop'
}

export const CARD_PROVIDER = {
  VISA: 'visa',
  MASTERCARD: 'mastercard',
  UNKNOWN: 'unknown'
}

export const TABS = {
  shop: {
    products:  {
      id: 1,
      type: 'Products'
    },
    about: {
      id: 2,
      type: 'About'
    },
    reviews: {
      id: 3,
      type: 'Reviews'
    },
  },
  getTabs: (category, id) => {
    return Object.values(TABS[category]).find(tab => tab.id === id)
  }
}

const VISA_REGEX = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
const MASTERCARD_REGEX = new RegExp('^5[1-5][0-9]{14}$');
const MASTERCARD2_REGEX = new RegExp('^2[2-7][0-9]{14}$');

export function cardProvider(serial) {
  if (VISA_REGEX.test(serial)) {
    return CARD_PROVIDER.VISA
  } else if (MASTERCARD_REGEX.test(serial) || MASTERCARD2_REGEX.test(serial)) {
    return CARD_PROVIDER.MASTERCARD
  } else {
    return CARD_PROVIDER.UNKNOWN
  }
}

export const DEFAULT_IMG = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';


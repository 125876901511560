
export const getItemsToAdd = (parentItem, selectedItems) => {
  const itemsWithChildren = parentItem.children.filter(item => item.children && item.children.length)
  const items = parentItem.children.filter(item => !item.children)

  let itemsToAdd = items.filter(item => !selectedItems.includes(item))
  if (itemsWithChildren.length) {
    let childrenToAdd  = itemsWithChildren.reduce((accumulator, item) => {
      return [
        ...accumulator,
        ...item.children.filter(children => {
          return !selectedItems.includes(children)
        })]
    }, [])
    itemsToAdd = [...itemsToAdd, ...childrenToAdd]
  }
  return itemsToAdd
}

export const getItemsToRemove = (parentItem) => {
  const itemsWithChildren = parentItem.children.filter(item => item.children && item.children.length)
  let itemsToRemove = parentItem.children.filter(item => !item.children);

  if (itemsWithChildren.length) {
    let childrenToRemove  = itemsWithChildren.reduce((accumulator, item) => {
      return [
        ...accumulator,
        ...item.children]
    }, [])
    itemsToRemove = [...itemsToRemove, ...childrenToRemove]
  }
  return itemsToRemove
}

export const getIsAllItemsToRemove = (queue, fullList, tree) => {
  let queueHelper = []
  return [...queue]
    .map(item => {
      queueHelper.push(item)
      return toDeepItem(queueHelper, fullList, tree)
    })
    .filter(deepItem => deepItem.children && deepItem.children.find(children => children.isAll))
    .map(deepItem => deepItem.children.find(children => children.isAll))
}

export const toDeepItem = (queue, fullList, tree) => {
  let result = null;

  let list;
  if (fullList && fullList.length) {
    list = fullList;
  } else {
    list = tree;
  }
  for (let i = 0; i < list.length; i++) {
    result = findLast(queue, list[i].children);
    if (result && !Array.isArray(result)) {
      break;
    }
  }
  return result.length ? {children: result} : result;
}

export const findLast = (queue, level) => {
  for (let i = 0; i < queue.length; i++) {
    for (let j = 0; j < level.length; j++) {
      if ((level[j].id + '-' + level[j].name) === queue[i]) {
        if (level[j].children && level[j].children.length) {
          const next = [...queue];
          next.shift();

          return findLast(next, level[j].children);
        } else {
          return level[j]
        }
      }
    }
  }
  return level;
}

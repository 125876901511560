import {BREAKPOINTS} from "assets/js/constants/constant";
import {nearestAspectRatio} from "assets/js/utils/aspect-ratio";
import imgProxyURL from "assets/js/utils/imgProxy";

const keys = Object.keys(BREAKPOINTS);

export const srcsetRatio = sizes => {
  return sizes.reduce((sizes, size, index) => {
    const s = size.split(':');
    return sizes + ' ' + nearestAspectRatio(s[0], s[1], index > 0 ? keys[index] + ':' : '');
  }, '');
}

export const src = (size, src, resizing, rotation) => {
  const baseFormat = src && src.indexOf('.png') > -1 ? 'png' : 'jpg';
  const s = size.split(':');
  return [
    process.env.imgProxy + imgProxyURL(src, s[0], s[1], baseFormat, resizing, 'ce', rotation),
    process.env.imgProxy + imgProxyURL(src, s[0], s[1], 'webp', resizing, 'ce', rotation),
   // process.env.imgProxy + imgProxyURL(src, s[0], s[1], 'avif', resizing),
  ]
}

export const srcset = (sizes, src, resizing, rotation) => {

  let base = '';
  let sources = [];

  const baseFormat = src && src.indexOf('.png') > -1 ? 'png' : 'jpg';
  const attributes = {};
  sources = sizes.reduce((sizes, size, index) => {

    const s = size.split(':');

    attributes[keys[index]] = {
      width : s[0],
      height : s[1]
    }

    return [
      ...sizes,
      {
        type: 'image/' + baseFormat,
        srcset: process.env.imgProxy + imgProxyURL(src, s[0], s[1], baseFormat, resizing, 'ce', rotation),
        media: '(min-width: ' + BREAKPOINTS[keys[index]] + ')'
      },
      {
        type: 'image/webp',
        srcset: process.env.imgProxy + imgProxyURL(src, s[0], s[1], 'webp', resizing, 'ce', rotation),
        media: '(min-width: ' + BREAKPOINTS[keys[index]] + ')'
      },
      /*{
        type: 'image/avif',
        srcset: process.env.imgProxy + imgProxyURL(src, s[0], s[1], 'avif', resizing),
        media: '(min-width: ' + BREAKPOINTS[keys[index]] + ')'
      },*/
    ]
  }, []);

  const s = sizes[0].split(':');
  base = process.env.imgProxy + imgProxyURL(src, s[0], s[1], baseFormat, resizing, 'ce', rotation);

  return {
    sources: src && src.length !== 1 ? sources.reverse() : [],
    base,
    attributes
  }
}

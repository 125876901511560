const createHmac = require('create-hmac')

const urlSafeBase64 = (s) => {
  return Buffer.from(s).toString('base64').replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

const hexDecode = (hex) => Buffer.from(hex, 'hex')

const sign = (salt, target, secret) => {
  const hmac = createHmac('sha256', hexDecode(secret))
  hmac.update(hexDecode(salt))
  hmac.update(target)
  return urlSafeBase64(hmac.digest())
}

const width = 300
const height = 300
const enlarge = 0

const imgProxyURL = (src, width = 200, height = 200, extension = 'jpg', resizing_type = 'fill', gravity = 'ce', rotation = 0) => {
  const resize = `rs:${resizing_type}:${width}:${height}:1`;
  const path = `/${resize}/q:75/g:${gravity}/${rotation > 0 ? 'rotate:' + rotation + '/' :''}${urlSafeBase64(src)}.${extension}`;
  const signature = process.client ? sign(process.env.imgProxySalt, path, process.env.imgProxyKey) : sign(process.env.IMG_PROXY_SALT, path, process.env.IMG_PROXY_KEY);
  return `/${signature}${path}`;
}

export default imgProxyURL;

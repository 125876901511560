export const isOneDayAgo = (date) => {
  const oneDay = 1000 * 60 * 60 * 24;
  const oneDayAgo = Date.now() - oneDay;

  return date.getTime() > oneDayAgo;
}

export const isTomorrow = (date) => {
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)

  return date.getDate() === tomorrow.getDate() &&
    date.getMonth() === tomorrow.getMonth() &&
    date.getFullYear() === tomorrow.getFullYear()
}

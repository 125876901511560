export const nearestAspectRatio = (width, height, prefix = '') => {
  let
    ratio = (width * 100) / (height * 100),
    maxW = 3 in arguments ? arguments[2] : 16,
    maxH = 4 in arguments ? arguments[3] : 16,
    ratiosW = new Array(maxW).join(',').split(','),
    ratiosH = new Array(maxH).join(',').split(','),
    ratiosT = {},
    ratios = {},
    match,
    key;

  ratiosW.forEach(function (empty, ratioW) {
    ++ratioW;
    ratiosH.forEach(function (empty, ratioH) {
      ++ratioH;
      const ratioX = (ratioW * 100) / (ratioH * 100);
      if (!ratiosT[ratioX]) {
        ratiosT[ratioX] = true;
        ratios[prefix + 'aspect-w-' + ratioW + ' ' + prefix + 'aspect-h-' + ratioH] = ratioX;
      }
    });
  });

  for (key in ratios) {
    if (!match || (
       Math.abs(ratio - ratios[key]) < Math.abs(ratio - ratios[match])
    )) {
      match = key;
    }
  }
  return match;
}
/*
 || (
      side < 0 && ratios[key] <= ratio && Math.abs(ratio - ratios[key]) < Math.abs(ratio - ratios[match])
    ) || (
      side > 0 && ratios[key] >= ratio && Math.abs(ratio - ratios[key]) < Math.abs(ratio - ratios[match])
    )
 */

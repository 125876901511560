export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const toCurrency = (price, $i18n) => {
  return price ? $i18n.n(price, 'trailingCurrency', 'fr') : $i18n.n(0, 'trailingCurrency', 'fr')
}

export const brandLink = (brand, $i18n) => {
  return brand+'-'+$i18n.t('occasion')
}
export const brandSlug = (brand, $i18n) => {
  let array = brand ? brand.split('-'+$i18n.t('occasion')) : null
  return array && array.length ? array[0] : null;
}

const formatter = Intl.NumberFormat('en', { notation: 'compact' })

export const numberToCompact = (number, trailingLetter = true) => {
  if (number >= 100000) {
    const formattedNumber = formatter.format(number)
    return trailingLetter ? formattedNumber :  formattedNumber.substr(0,formattedNumber.length-1);
  } else {
    return number
  }
}

export const GTM_EVENTS = {
  navigation: {
    pageView: 'page_view',
    navigatedTo: 'navigated_to',
  },
  authentication: {
    view: 'authentication_view',
    begin: 'begin_authentication',
  },
  login: {
    failure: 'login_failure',
    success: 'login_success',
  },
  signUp: {
    failure: 'sign_up_failure',
    success: 'sign_up_success',
  },
  list: {
    filter: 'filter',
    viewItem: 'view_item_list',
  },
  item: {
    select: 'select_item',
    view: 'view_item',
    like: 'like',
  },
  cart: {
    add: 'add_to_cart'
  },
  checkout: {
    begin: 'begin_checkout',
    beginDelivery: 'begin_delivery',
    addShippingInfo: 'add_shipping_info',
    beginPayment: 'begin_payment',
    purchase: 'purchase',
  },
  search: {
    begin: 'begin_search',
    validated: 'search',
  },
  conversations: {
    message: 'message',
    makeOffer: 'make_offer',
    acceptOffer: 'accept_offer',
    declineOffer: 'decline_offer',
  },
  product: {
    uploadView: 'product_upload_view',
    addPhotos: 'add_product_photos',
    addInfo: 'add_product_info',
    addGeneralInfo: 'add_general_info',
    addTechnicalInfo: 'add_technical_info',
    addDeliveryInfo: 'add_delivery_info',
    publish: 'publish_product',
  },
  app: {
    install: 'app_install',
  },
}



const getFormattedCategories = (categories, prefix = 'item_category', useFirstIndex = false) => {
  const formattedCategories = {};

  categories.forEach((category, index) => {
    const formattedIndex = useFirstIndex
      ? index + 1
      : index > 0
        ? index + 1
        : ''
    formattedCategories[`${prefix}${formattedIndex}`] = category.name;
  });

  return formattedCategories;
}



export const USER_LOGIN_STATUSES = {
  loggedIn: 'logged in',
  loggedOut: 'logged out',
}



export const NAVIGATION_LOCATIONS = {
  breadcrumb: 'breadcrumb',
  footer: 'footer',
  header: 'header',
}

export const navigatedToParams = (title, location, categories) => ({
  event: GTM_EVENTS.navigation.navigatedTo,
  navigation: {
    title,
    location,
    ...getFormattedCategories(categories, 'category', true),
  }
})



export const LOGIN_METHODS = {
  apple: 'apple',
  email: 'email',
  facebook: 'facebook',
  google: 'google',
}



export const CARD_ADDING_METHODS = {
  standard: 'standard',
  chat: 'chat',
}



export const ITEM_LISTS = {
  catalog: 'catalog',
  favorites: 'favorites',
  home: 'home',
  myShop: 'my-shop',
  userShop: 'user-shop',
  similarProducts: 'similar-products'
}



export const getEcommerceItems = (items) => {
  return {
    ecommerce: {
      items: items.map(({ id, name, brand, categories, price, quantity, itemState, itemListName, index }) => {
        return {
          item_id: id,
          item_name: name,
          item_brand: brand,
          ...getFormattedCategories(categories),
          price: Number(parseFloat(price).toFixed(2)),
          quantity,
          item_state: itemState,
          item_list_name: itemListName,
          index,
        }
      }),
    },
  }
}

export const getCatalogPath = (catalog) => {
  let path = [catalog]
  if (catalog.parent) {
    path.unshift(catalog.parent)
    if (catalog.parent.parent) {
      path.unshift(catalog.parent.parent)
    }
  }
  return path
}
